import {
  IAppliedPromotion,
  ICatalogOffer,
  ICatalogOfferReward,
  IOfferAlert,
  IExpiredPromotion,
  IMoleculeAlert,
  OFFER_REWARD_TYPE
} from '@belong/types';
import {
  IAppliedOfferReward,
  IOffer,
  IOfferReward,
  OFFER_REWARD_ATTRIBUTE_NAME,
  OFFER_REWARD_FULFILMENT_TYPE,
  OFFER_STATUS
} from '@belong/types/api/offers';
import { IOfferDetails } from '@belong/types/api/orders';
import { normaliseRewardProperties } from './templates';

export type PromotionType = IAppliedPromotion;
export type OfferType = IOffer | ICatalogOffer | IOfferDetails;
export type RewardType = IOfferReward | ICatalogOfferReward | IAppliedOfferReward;
export type AnyOfferOrReward = PromotionType | OfferType | RewardType;

export const shouldLoadOrderOfferDetails = (offer: IOfferDetails): boolean =>
  [OFFER_STATUS.ACTIVE, OFFER_STATUS.NEW].includes(offer.status);

export const isOffer = (item?: AnyOfferOrReward): item is IOffer | ICatalogOffer => {
  if (!item) {
    return false;
  }
  return 'rewards' in item;
};

export const isOfferReward = (item?: AnyOfferOrReward): item is IAppliedOfferReward | ICatalogOfferReward => {
  // Applied Offer Rewards MUST have an `offerId`
  // OfferRewards & CatalogOfferRewards MUST have `productAttributes`
  // Anything else is NOT an offer reward
  if (!item) {
    return false;
  }

  // IAppliedOfferReward
  if ('offerId' in item) {
    return true;
  }

  // IOfferReward || ICatalogOfferReward
  return 'productAttributes' in item;
};

export const isAppliedPromotion = (item?: AnyOfferOrReward): item is IAppliedPromotion => {
  if (!item) {
    return false;
  }
  return 'remainingMonths' in item && 'code' in item;
};

export const isAppliedOfferReward = (item?: AnyOfferOrReward): item is IAppliedOfferReward => {
  if (!item) {
    return false;
  }
  return 'offerId' in item && !('productAttributes' in item);
};

export const getRewardType = (item?: RewardType | PromotionType): OFFER_REWARD_TYPE => {
  if (!item) {
    return OFFER_REWARD_TYPE.UNKNOWN;
  }

  // Applied Promo: Remove after 01/02/2025
  if (isAppliedPromotion(item)) {
    return OFFER_REWARD_TYPE.DATA;
  }
  // Data Reward
  if (isDataReward(item)) {
    return OFFER_REWARD_TYPE.DATA;
  }
  // Credit Reward
  if (isCreditReward(item)) {
    return OFFER_REWARD_TYPE.CREDIT;
  }

  // Mystery item
  return OFFER_REWARD_TYPE.UNKNOWN;
};

export const getRewardFulfilmentType = (item?: RewardType | PromotionType): OFFER_REWARD_FULFILMENT_TYPE => {
  if (!item) {
    return OFFER_REWARD_FULFILMENT_TYPE.UNKNOWN;
  }

  // Applied Promo: Remove after 01/02/2025
  if (isAppliedPromotion(item)) {
    return OFFER_REWARD_FULFILMENT_TYPE.IMMEDIATE;
  }

  return normaliseRewardProperties(item)[OFFER_REWARD_ATTRIBUTE_NAME.FULFILMENT_TYPE] as OFFER_REWARD_FULFILMENT_TYPE;
};

export const isDataReward = (item?: RewardType): boolean => {
  if (!item) {
    return false;
  }
  return OFFER_REWARD_ATTRIBUTE_NAME.DATA_SIZE in normaliseRewardProperties(item);
};

export const isCreditReward = (item?: RewardType): boolean => {
  if (!item) {
    return false;
  }
  return OFFER_REWARD_ATTRIBUTE_NAME.CREDIT_AMOUNT in normaliseRewardProperties(item);
};

// TRUE if reward has affected the service in the CURRENT bill cycle
export const isActiveReward = (reward: IAppliedOfferReward): boolean => {
  return (reward?.remainingMonths ?? 0) < (reward?.durationInMonths ?? 0);
};

// TRUE if reward will affect the service in the NEXT bill cycle
// (Assumes reward is either active or will start in next bill cycle)
export const isOnGoingReward = (reward: IAppliedOfferReward | IAppliedPromotion | IExpiredPromotion): boolean => {
  return (reward?.remainingMonths ?? 0) > 0;
};

// TRUE if reward duration is greater than 0
export const isRecurringReward = (reward?: IAppliedOfferReward | ICatalogOfferReward): boolean => {
  if (!reward) {
    return false;
  }

  let duration = 0;
  if (isAppliedOfferReward(reward)) {
    duration = reward.durationInMonths ?? 0;
  } else {
    duration = parseInt((reward?.productAttributes[OFFER_REWARD_ATTRIBUTE_NAME.DURATION] ?? 0).toString(), 10);
  }

  return duration > 1;
};

export const isOfferAlertTemplate = (template?: IOfferAlert | IMoleculeAlert): template is IOfferAlert => {
  if (!template) {
    return false;
  }
  return 'callout' in template;
};

export const isMoleculeAlertTemplate = (template?: IOfferAlert | IMoleculeAlert): template is IMoleculeAlert => {
  if (!template) {
    return false;
  }
  return 'message' in template;
};

export const getIdFromReward = (reward: IAppliedOfferReward | IAppliedPromotion): string => {
  if (isAppliedOfferReward(reward)) {
    return reward.offerId;
  }
  if (isAppliedPromotion(reward)) {
    return reward.id;
  }

  return '';
};

export const getDurationFromReward = (
  reward: IAppliedOfferReward | IAppliedPromotion | ICatalogOfferReward | IOfferReward
): number => {
  if (isAppliedOfferReward(reward)) {
    return reward.durationInMonths;
  }
  if (isAppliedPromotion(reward)) {
    return reward.duration;
  }

  return 0;
};
