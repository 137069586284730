import {
  IAppliedOfferReward,
  IOfferReward,
  OFFER_REWARD_ATTRIBUTE_NAME,
  OFFER_REWARD_FULFILMENT_TYPE
} from '@belong/types/api/offers';
import {
  IAppliedPromotion,
  ICatalogOffer,
  ICatalogOfferReward,
  IDictionary,
  OFFER_REWARD_TYPE,
  OFFER_REWARD_UNIT,
  ProductAttributeMap
} from '@belong/types';
import { logger } from '@belong/logging';
import { pluralise } from '@belong/utils';
import { formatDate } from '@belong/date-utils';
import {
  getDurationFromReward,
  getRewardType,
  isAppliedOfferReward,
  isAppliedPromotion,
  isOffer,
  isOfferReward,
  isRecurringReward
} from '../utils';
import { OFFER_DATE_FORMAT, OFFER_TEMPLATE_TYPE, REWARD_RECURRENCE, REWARD_TEMPLATE_NAMES } from './config';
import { collectProductAttributes } from '../factories';

// Only use when displaying pre-activation Offers
export function offerToDictionary(offer?: ICatalogOffer): IDictionary {
  if (!offer) {
    return {};
  }

  if (isOffer(offer)) {
    // ‼️NOTE: Currently only supports one reward per offer
    return {
      'offer:id': offer.id,
      'offer:name': offer.name,
      ...toOfferDateDictionary('offer:expiry', offer.endDate),
      ...rewardToDictionary(offer.rewards[0])
    };
  }

  logger.error('OfferToDictionary: Unsupported offer type', offer);
  return {};
}

// !! FORCE AMOUNT TO ZERO FOR CREDIT REWARDS !!
// Note: Keep dictionary keys the same across offer/promotion to avoid having to change content
export function rewardToDictionary(
  reward?: IAppliedOfferReward | IAppliedPromotion | ICatalogOfferReward | IOfferReward
): IDictionary {
  if (!reward) {
    return {};
  }

  // Offer Rewards
  if (!isAppliedPromotion(reward)) {
    const properties = normaliseRewardProperties(reward);
    const amount =
      getRewardType(reward) === OFFER_REWARD_TYPE.DATA
        ? formatAmount(OFFER_REWARD_TYPE.DATA, properties[OFFER_REWARD_ATTRIBUTE_NAME.DATA_SIZE] as number)
        : formatAmount(OFFER_REWARD_TYPE.CREDIT, 0);

    const dictionary: IDictionary = {
      'reward:amount': amount,
      'reward:duration': formatDuration(
        OFFER_REWARD_UNIT.duration,
        properties[OFFER_REWARD_ATTRIBUTE_NAME.DURATION] as number
      )
    };

    // Only add start dates for applied rewards
    if (isAppliedOfferReward(reward)) {
      Object.assign(dictionary, {
        ...toOfferDateDictionary('reward:start', reward.startDate),
        ...toOfferDateDictionary('reward:expiry', reward.expiryDate)
      });
    }

    return dictionary;
  }

  // Promotion (LEGACY / *No Name or Start Fields* / *Only Data Rewards*)
  return {
    // support both offer-style (new) and promo-style (old) tokens
    // Remove legacy camelCase properties when contentful is updated in production
    dataAmount: formatAmount(OFFER_REWARD_TYPE.DATA, reward.amount.value),
    expiryDate: formatDate(reward.expiryDate),
    'offer:id': reward.id,
    'offer:name': reward.name,
    'reward:amount': formatAmount(OFFER_REWARD_TYPE.DATA, reward.amount.value),
    'reward:duration': formatDuration(OFFER_REWARD_UNIT.duration, reward.duration || reward.remainingMonths),
    'reward:start': 'now',
    'reward:start:short': 'now',
    'reward:start:long': 'now',
    ...toOfferDateDictionary('reward:expiry', reward.expiryDate)
  };
}

export const normaliseRewardProperties = (
  reward: IOfferReward | ICatalogOfferReward | IAppliedOfferReward
): ProductAttributeMap => {
  if (!reward) {
    return {};
  }

  // IAppliedOfferReward
  if (isAppliedOfferReward(reward)) {
    const { offerId, offerType, ...rewardProps } = reward;
    return rewardProps;
  }

  // IOfferReward (API Response)
  if (Array.isArray(reward.productAttributes)) {
    return collectProductAttributes(reward.productAttributes);
  }

  // ICatalogOfferReward
  return reward.productAttributes;
};

function formatAmount(type: OFFER_REWARD_TYPE, value: number): string {
  switch (type) {
    case OFFER_REWARD_TYPE.DATA:
      return `${value}${OFFER_REWARD_UNIT.data.toUpperCase()}`;
    case OFFER_REWARD_TYPE.CREDIT:
      return `${OFFER_REWARD_UNIT.credit}${value}`;
    default:
      logger.error('formatReward() - Unknown reward type', type);
      return '';
  }
}

function formatDuration(unit: string, value: number): string {
  return `${value} ${pluralise(value, unit.toLowerCase(), 's')}`;
}

export const getRewardTemplateName = (
  reward: IAppliedOfferReward | IAppliedPromotion | ICatalogOfferReward | IOfferReward,
  type: OFFER_TEMPLATE_TYPE
): string => {
  // Legacy promo reward - only DATA promos supported
  if (!isOfferReward(reward)) {
    const recurrence = getDurationFromReward(reward) > 1 ? REWARD_RECURRENCE.RECURRING : REWARD_RECURRENCE.ONCE_OFF;

    return REWARD_TEMPLATE_NAMES.data[recurrence][type];
  }

  // Figure out which template to use based on reward property keys
  const props = normaliseRewardProperties(reward);
  const isDataReward = OFFER_REWARD_ATTRIBUTE_NAME.DATA_SIZE in props;
  const isBillCycleAligned =
    props[OFFER_REWARD_ATTRIBUTE_NAME.FULFILMENT_TYPE] === OFFER_REWARD_FULFILMENT_TYPE.BILL_CYCLE_ALIGNED;

  if (isDataReward) {
    const recurrence = isRecurringReward(reward) ? REWARD_RECURRENCE.RECURRING : REWARD_RECURRENCE.ONCE_OFF;
    const templateGroup = REWARD_TEMPLATE_NAMES.data[recurrence];

    // Not all templates have a billCycleAligned variant - fall through to standard if not supported
    if (isBillCycleAligned) {
      return templateGroup[OFFER_REWARD_FULFILMENT_TYPE.BILL_CYCLE_ALIGNED][type] ?? templateGroup[type];
    }
    return templateGroup[type];
  }

  // Only data templates/alerts are currently supported
  throw new Error('OfferContentManager:getRewardTemplateName() - Unsupported reward type');
};

// Create a dictionary that includes the date in the required formats for use in Offer Templates
export const toOfferDateDictionary = (label: string, date: string | number | Date): IDictionary => ({
  [label]: formatDate(date, OFFER_DATE_FORMAT.STANDARD),
  [`${label}:short`]: formatDate(date, OFFER_DATE_FORMAT.SHORT),
  [`${label}:long`]: formatDate(date, OFFER_DATE_FORMAT.LONG)
});
