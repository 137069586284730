import React from 'react';
import dynamic from 'next/dynamic';
import { ThemeProvider } from 'styled-components';
import { Cookies } from 'react-cookie';
import App, { AppContext } from 'next/app';

import { COOKIES } from '@belong/constants';
import { getNativeAppCookie } from '@belong/cookies';
import { GlobalStyles, THEME } from '@belong/themes';
import { getConfig } from '@belong/configs/next/config';
import { PlatformScript, PolyfillScript } from '@belong/ui-components/components/global/Scripts';
import { setBlueHost } from '@belong/utils/requestOrigin';
import { getCurrentEnvironment, logAppInfo } from '@belong/utils/env/env';
import {
  ILegacyPublicRuntimeConfig,
  initPlatformConfig,
  getCorrelationId,
  isBrowser,
  withBasePath,
  getFullServerOrClientUrl
} from '@belong/utils';
import { ENVS, IHeaderMenu, IFooterMenu } from '@belong/types';
import FeatureProviders from '@belong/providers';

import { GlobalErrorHandler, logger } from '@belong/logging';
import {
  FEATURES,
  FeaturesManager,
  FeatureTogglesContext,
  IFeatureToggles,
  getFeatureToggles
} from '@belong/feature-toggles';
import { getWebsiteConfig } from '@belong/contentful';

import { ISiteOfferContent, offerContentManager } from '@belong/offers';
import { AnalyticsEvents } from '@belong/analytics';
import { getAppProps } from '@belong/utils/common-app-bootstrap-utils/getAppProps';
import CanonicalLink from '@belong/ui-components/components/global/CanonicalLink';
import { ChatIframer } from '@belong/chat-utils';
import { SearchProvider, ISearchServer } from '@belong/search';
import AnalyticTracker from '../components/AnalyticsTracker';
import LayoutShell from '../components/LayoutShell';
import { routeConfig } from '../helpers/bootstrap';
import { IDynamicPageProps } from './[[...slug]]';
import ErrorPage from './_error';

const Toolbox = dynamic(() => import('@belong/toolbox/src/view'));

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    BELONG: {
      analyticsEvents: AnalyticsEvents;
      redirectActivateSim?: (linkKey: string, url: string) => void;
      redirectOrderSim?: (linkKey: string, url: string) => void;
    };
  }
}

interface IPageProps extends ISearchServer {
  correlationId: string;
  cookie?: string;
  features: IFeatureToggles;
  offerContent: ISiteOfferContent;
  footerMenuContent?: IFooterMenu;
  headerMenuContent?: IHeaderMenu;
  pageData?: any;
  statusCode?: number;
  host?: string;
  isBlue: boolean;
}

interface IInitialProps {
  pageProps: IPageProps;
}

const setupAxe = () => {
  if (isBrowser() && getCurrentEnvironment() !== ENVS.PRODUCTION) {
    import('react-dom').then(ReactDOM => {
      import('@axe-core/react').then(axe => {
        axe.default(React, ReactDOM, 1000, {});
      });
    });
  }
};

export default class PublicApp extends App<IInitialProps> {
  constructor(props) {
    super(props);
    const { pageProps } = props;
    const { cookie, correlationId, features } = pageProps;

    FeaturesManager.init(correlationId, features);
    offerContentManager.init(pageProps?.offerContent);

    initPlatformConfig(getNativeAppCookie(cookie));

    if (isBrowser()) {
      setBlueHost(window.location.host);
    } else {
      setBlueHost(pageProps.host);
    }
  }

  static async getInitialProps({ Component, ctx }: AppContext): Promise<IInitialProps> {
    const correlationId = getCorrelationId(ctx);
    const pageProps = { ...getAppProps(ctx), correlationId };

    logger.debug(`App.getInitialProps("${ctx.asPath}")`);

    // check for a CONTENTFUL_SETTINGS cookie to modify the app config
    const parsedCookie = new Cookies(pageProps.cookie);
    const contentfulEnvironment = parsedCookie.get(COOKIES.CONTENTFUL_SETTINGS)?.environment;

    // only need to pass cookies on the server as cookies will
    // automatically pull from document.cookie in the browser
    const [{ headerMenuContent, footerMenuContent }, features] = await Promise.all([
      getWebsiteConfig(contentfulEnvironment),
      getFeatureToggles(
        getConfig().publicRuntimeConfig.featureTogglesApiUrl,
        pageProps.cookie,
        pageProps.isBlue,
        correlationId
      )
    ]);

    // TODO - remove once all calls FeatureToggles.isFeatureOn are gone
    FeaturesManager.init(correlationId, features);

    // ToDo: Once OFFER_MANAGEMENT_V2 feature toggle is enabled for all environments, put this back into the promise.all
    await offerContentManager.load(correlationId, pageProps.cookie);

    if (ctx.res && pageProps.statusCode) {
      ctx.res.statusCode = pageProps.statusCode;
    }

    if (Component.getInitialProps) {
      const propsForPage = (await Component.getInitialProps(ctx)) as IDynamicPageProps;

      if (propsForPage) {
        const pageDataWithPromotions = offerContentManager.applySectionOverrides(
          propsForPage.pageData,
          withBasePath(ctx.asPath),
          correlationId
        );
        Object.assign(pageProps, propsForPage, { pageData: pageDataWithPromotions });
      }
    }

    return {
      pageProps: {
        ...pageProps,
        headerMenuContent,
        footerMenuContent,
        offerContent: offerContentManager.export(),
        features,
        searchServerUrl: getFullServerOrClientUrl(ctx)
      }
    };
  }

  publicRuntimeConfig: ILegacyPublicRuntimeConfig = getConfig().publicRuntimeConfig;

  componentDidMount() {
    const { pageProps } = this.props;
    logAppInfo('Public', pageProps.correlationId);

    // set api host blue state (required in on-mount for the initial render)
    setBlueHost(window.location.host);

    setupAxe();
  }

  componentDidUpdate(prevProps: IInitialProps) {
    const { pageProps } = this.props;
    if (pageProps.correlationId !== prevProps.pageProps.correlationId) {
      logAppInfo('Public', pageProps.correlationId);
    }
    setupAxe();
  }

  render() {
    const { Component, pageProps } = this.props;
    const { correlationId, features, headerMenuContent, footerMenuContent, pageData, searchServerUrl } = pageProps;
    const allCookies = new Cookies(pageProps.cookie);
    const additionalProviders: React.ComponentType<any>[] = [];

    const { analytic } = this.publicRuntimeConfig;

    return (
      <ThemeProvider theme={THEME}>
        <GlobalErrorHandler appRoutes={routeConfig.routes} />
        <PlatformScript />
        <PolyfillScript scriptName={'broadcastchannel.min.js'} />
        <PolyfillScript scriptName={'inert.min.js'} />
        <FeatureTogglesContext.Provider value={features}>
          <FeatureProviders
            cookies={allCookies}
            correlationId={correlationId}
            additionalProviders={additionalProviders}
          >
            <SearchProvider searchServerUrl={searchServerUrl}>
              <CanonicalLink pageUrl={pageData?.identifier} />
              <AnalyticTracker page={pageProps} {...analytic} />
              <GlobalStyles hasBodyScrollLock />
              {!headerMenuContent || !footerMenuContent ? (
                <ErrorPage statusCode={500} />
              ) : (
                <LayoutShell
                  pageAlert={pageData?.alert}
                  shouldHaveLiveChat={!!pageData?.shouldHaveLiveChat}
                  headerMenuContent={headerMenuContent}
                  footerMenuContent={footerMenuContent}
                  breadcrumbs={pageData?.breadcrumbs as any}
                >
                  <Component {...pageProps} />
                  {features[FEATURES.LIVE_CHAT_IFRAME] && <ChatIframer />}
                </LayoutShell>
              )}
              {getCurrentEnvironment() !== ENVS.PRODUCTION && (
                <Toolbox pageProps={pageProps} runtimeConfig={this.publicRuntimeConfig} />
              )}
            </SearchProvider>
          </FeatureProviders>
        </FeatureTogglesContext.Provider>
      </ThemeProvider>
    );
  }
}
