import { Entry } from 'contentful';
import { FRichText } from '@belong/contentful';
import { IOfferTemplate } from '@belong/types';

export const FOfferTemplate = (data: Entry<any>): IOfferTemplate => {
  const { title, description, colour, code } = data.fields;
  const { id } = data.sys;

  return {
    title: title && FRichText(title, id),
    description: description && FRichText(description, id),
    colour,
    code
  };
};
