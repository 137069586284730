import type { IOfferCampaign } from '@belong/types';
import type { Entry } from 'contentful';
import {
  FSectionPrimaryBanner,
  FOrganismSecondaryTile,
  FRichText,
  FMoleculeAlert,
  FElementOfferAlert
} from '@belong/contentful/factories';
import { FCatalogOffer } from './CatalogOffer.factory';
import { FOfferTemplate } from './OfferTemplate.factory';

const isActive = (item: { isActive: boolean }): boolean => item.isActive;

export const FOfferCampaign = (item: Entry<any>): IOfferCampaign => {
  const {
    offers,
    homepagePrimaryBanner,
    mobilePlanPageBanner,
    offerSecondaryTile,
    orderSimMessage,
    activationPromoAlert,
    activationOfferAlert,
    draftServiceReminder,
    registerSimAlert
  } = item.fields;

  return {
    ...item.fields,
    offers: offers.map(FCatalogOffer).filter(isActive),
    homepagePrimaryBanner: homepagePrimaryBanner && FSectionPrimaryBanner(homepagePrimaryBanner),
    mobilePlanPageBanner: mobilePlanPageBanner && FSectionPrimaryBanner(mobilePlanPageBanner),
    offerSecondaryTile: offerSecondaryTile && FOrganismSecondaryTile(offerSecondaryTile),
    orderSimMessage: orderSimMessage && FRichText(orderSimMessage),
    activationPromoAlert: activationPromoAlert && FElementOfferAlert(activationPromoAlert),
    activationOfferAlert: activationOfferAlert && FOfferTemplate(activationOfferAlert),
    draftServiceReminder: draftServiceReminder && FRichText(draftServiceReminder),
    registerSimAlert: registerSimAlert && FMoleculeAlert(registerSimAlert)
  };
};
